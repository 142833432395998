<template>
  <b-container>
    <b-row class="mt-5">
      <b-col><curva-title :title="$t('productDetails.purchase')"/></b-col>
    </b-row>
    <b-row>
      <b-col lg="3" class="mt-5">
        <div class="order-summary">
          <p class="title text-center">{{ $t('cart.summary') }}</p>
          <b-row><b-col>{{ $t('cart.tItems') }}</b-col>
            <b-col class="text-right">{{ orderSummary.items }}</b-col></b-row>
          <b-row><b-col>{{ $t('cart.subtotal') }}</b-col>
            <b-col class="text-right">{{ orderSummary.subtotal }}
              <span class="currency">{{$t('egp')}}</span></b-col></b-row>
          <b-row><b-col>{{ $t('cart.tax') }}</b-col><b-col class="text-right">{{ orderSummary.tax }}
            <span class="currency">{{$t('egp')}}</span></b-col></b-row>
            <!-- {{  orderSummary  }} -->
          <b-row><b-col>{{ $t('cart.fees') }}</b-col><b-col class="text-right">{{ orderSummary.deliveryFees }}
            <span class="currency">{{$t('egp')}}</span></b-col></b-row>
          <b-row class="total-row"><b-col>{{ $t('cart.total') }}</b-col><b-col class="text-right">
            {{ orderSummary.total }}
            <span class="currency">{{$t('egp')}}</span></b-col></b-row>
        </div>
      </b-col>
      <b-col lg="9">
        <form-wizard
            title="" subtitle="" ref="wizard"
        >
          <tab-content :title="$t('wizard.step1')" icon="las la-user">
              <validationObserver v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(order)" class="curva-form curva-form-up iq-style4 mb-5">
                  <b-row class="justify-content-center">
                    <b-col lg="6" class="pt-3">
                      <b-row>
                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.name') + ' (min 8 characters)'"
                                      v-model="purchaseDetails.fullname"
                                      :validate="'required|min:8'" :name="`Name`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <main-select :placeholder="$t('forms.state')"
                                       v-model="address.state_id"
                                       :options="allStates"
                                       label="name"
                                       :reduce="item=>item.id"
                                       :validate="'required'"
                                        name="state"
                                        class="curva-main-select"
                          ></main-select>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <main-select :placeholder="$t('forms.city')"
                                       v-model="address.city_id"
                                       :options="allCities" label="name"
                                       :reduce="item=>item.id"
                                       :name="`city`"
                                       :validate="'required'"
                                       class="curva-main-select"
                          ></main-select>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <main-select :placeholder="$t('forms.region')"
                                       v-model="address.region_id"
                                       :options="allRegions" label="name"
                                       :reduce="item=>item.id"
                                       :validate="'required'" :name="`region`"
                                        class="curva-main-select"
                                        @select="orderSummary.deliveryFees = item.shipping_cost"
                          ></main-select>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.street')" v-model="address.street"
                                      :validate="'required'" :name="`Address`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.block_no')"
                                      v-model="address.block_no"
                                      :validate="'required|numeric'" :name="`block_no`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.floor_no')"
                                      v-model="address.floor_no"
                                      :validate="'required|numeric'" :name="`floor_no`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.flat_no')"
                                      v-model="address.flat_no"
                                      :validate="'required|numeric'"
                                      :name="`flat_no`"
                                    />
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.phone')" v-model="address.phone"
                                      :validate="'required|numeric|digits:11'" :name="`Mobile number`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <input-form :placeholder="$t('forms.phone2')" v-model="address.phone2"
                                      :validate="'required|numeric|digits:11'" :name="`Second mobile number`"/>
                        </b-col>

                        <b-col lg="12" class="mb-1">
                          <b-input-group>
                            <input-form :placeholder="$t('cart.coupon')" v-model="purchaseDetails.copoun" class="flex-grow-1"
                                        custom-class="curva-control-input"/>
                            <b-input-group-append>
                              <b-button class="input-btn">{{ $t('cart.verify') }}</b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>

                        <b-col lg="12">
                          <b-form-group
                              label-for="Notes"
                          >
                            <ValidationProvider name="notes" ref="Notes" v-slot="{ errors }">
                              <b-form-textarea
                                  :placeholder="$t('cart.notes')+' ..'"
                                  rows="2" v-model="purchaseDetails.notes"
                                  :class="['curva-textarea', errors.length > 0 ? ' is-invalid' : '']"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="12" class="mb-1 d-flex gap-2">
                          <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="terms" color="warning"
                                           name="color" >
                            <span class="text-gray font-size-16 mx-4">{{ $t('cart.terms1') }}
                              <router-link :to="{ name: 'terms' }" class="text-warning">{{ $t('cart.terms2') }}</router-link></span>
                          </b-form-checkbox>
                        </b-col>

                        <b-col lg="12">
                          <b-button class="curva-granola-btn small-rounded-btn" type="submit" block>
                            {{ $t('cart.step3') }}</b-button>
                        </b-col>

                      </b-row>
                    </b-col>
                  </b-row>

                </b-form>
              </validationObserver>
          </tab-content>
          <template v-slot:footer><span> </span></template>
        </form-wizard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProductsServices from '@/modules/all-products/services/products.services'
import mainServices from '@/services/main'

export default {
  props: ['quantity', 'size', 'price', 'id', 'barcode'],
  data () {
    return {
      orderSummary: {
        items: 1,
        subtotal: 0,
        tax: 0,
        deliveryFees: 0,
        total: 0
      },
      purchaseDetails: {
        notes: '',
        copoun: '',
        email: 'test@curva.com',
        birthday: '',
        gender: 'male',
        fullname: ''
      },
      address: {
        state_id: '',
        city_id: '',
        region_id: '',
        block_no: '',
        flat_no: '',
        floor_no: '',
        phone: '',
        phone2: '',
        street: ''
      },
      terms: false,
      genderOptions: [
        {
          key: 'male',
          value: this.$t('male')
        },
        {
          key: 'female',
          value: this.$t('female')
        }
      ],
      allStates: [],
      allCities: [],
      allRegions: []
    }
  },
  methods: {
    isLastStep () {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep
      }
      return false
    },
    next () {
      this.$refs.wizard.nextTab()
    },
    order () {
      if (!this.terms) {
        // event.preventDefault()
        core.showSnackbar('error', this.$t('forms.terms'))
      } else {
        ProductsServices.buyNow(this.$route.params.id, {
          quantity: this.quantity,
          barcode: this.barcode,
          productId: this.id,
          shipping: this.orderSummary.deliveryFees,
          ...this.address,
          ...this.purchaseDetails
        }).then(res => {
          core.showSnackbar('success', res.data.message)
          // if (localStorage.getItem('userToken')) {
          //   this.$router.push({ name: 'invoices', params: { client_id: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : null, order_id: res.data.data.order_id } })
          // } else {
          this.$router.push({ name: 'invoices', params: { client_id: res.data.data.client_id, order_id: res.data.data.order_id } })
          // }
        })
      }
    },
    isEng () {
      console.log(localStorage.getItem('lang'))
      return localStorage.getItem('lang') === 'en'
    },
    getAllStates () {
      mainServices.getState().then(res => {
        this.allStates = res.data.data
      })
    },
    getAllCities (id) {
      mainServices.getCities(id).then(res => {
        this.allCities = res.data.data
      })
    },
    getAllRegion (id) {
      mainServices.getRegions(id).then(res => {
        this.allRegions = res.data.data
      })
    },
    getValue (data) {
      this.orderSummary.deliveryFees = data.Cost
      this.orderSummary.total = this.orderSummary.subtotal + data.Cost
      this.barcode = this.orderSummary.barcode
    }
  },
  created () {
    if (this.size === undefined) {
      this.$router.push({ name: 'productDetails', params: { id: this.$route.params.id } })
    }
    this.getAllStates()
    this.orderSummary.items = this.quantity
    this.orderSummary.subtotal = this.quantity * this.price
    this.orderSummary.total = this.orderSummary.subtotal
  },
  watch: {
    'address.state_id': function (newVal) {
      this.allCities = []
      this.allRegions = []
      this.getAllCities(newVal)
    },

    'address.city_id': function (newVal) {
      this.allRegions = []
      this.getAllRegion(newVal)
    }

  },
  mounted () {
    core.index()
  }
}
</script>
